import styled from "styled-components";

export const PoodlePart = styled.img`
  position: absolute;
  @media all and (orientation: portrait) {
    /* Extra small devices (phones, 600px and down) */
    @media all and (max-width: 600px) {
      max-width: 300px;
      max-height: 300px;
      top: calc(80vh - 300px);
    }

    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media all and (min-width: 600px) {
      max-width: 450px;
      max-height: 450px;
      top: calc(80vh - 450px);
    }

    /* Medium devices (landscape tablets, 768px and up) */
    @media all and (min-width: 768px) {
      max-width: 550px;
      max-height: 550px;
      top: calc(80vh - 550px);
    }

    /* Large devices (laptops/desktops, 992px and up) */
    @media all and (min-width: 992px) {
      max-width: 575px;
      max-height: 575px;
      top: calc(80vh - 575px);
    }

    /* Extra large devices (large laptops and desktops, 1200px and up) */
    @media all and (min-width: 1200px) {
      max-width: 600px;
      max-height: 600px;
      top: calc(80vh - 600px);
    }
  }

  @media all and (orientation: landscape) {
    /* Extra small devices (phones, 600px and down, assuming 16:9 aspect ratio) */
    @media all and (max-height: 338px) {
      max-width: 150px;
      max-height: 150px;
      top: calc(100vh - 150px);
    }

    /* Small devices (portrait tablets and large phones, 600px and up, assuming 16:9 aspect ratio) */
    @media all and (min-height: 338px) {
      max-width: 215px;
      max-height: 215px;
      top: calc(100vh - 215px);
    }

    /* Medium devices (landscape tablets, 432px and up, assuming 16:9 aspect ratio) */
    @media all and (min-height: 432px) {
      max-width: 300px;
      max-height: 300px;
      top: calc(100vh - 300px);
    }

    /* Large devices (laptops/desktops, 558px and up, assuming 16:9 aspect ratio) */
    @media all and (min-height: 558px) {
      max-width: 430px;
      max-height: 4300px;
      top: calc(100vh - 430px);
    }

    /* Extra large devices (large laptops and desktops, 675px and up, assuming 16:9 aspect ratio) */
    @media all and (min-height: 675px) {
      max-width: 550px;
      max-height: 550px;
      top: calc(100vh - 550px);
    }
  }
`;
