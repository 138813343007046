import React from "react";
import { Container } from "./styled/Container.styled";
import { FooterContainer } from "./styled/FooterContainer.styled";
import { ResponsiveWrapper } from "./styled/ResponsiveWrapper.styled";
import { Link } from "react-router-dom";
import { SpacerSmall } from "./styled/SpacerSmall.styled";

function Footer({ useTerms }) {
  return (
    <FooterContainer>
      <ResponsiveWrapper flex={1} style={{ fontFamily: "poods-filler" }}>
        {useTerms && (
          <>
            <Container flex={1} jc={"center"} ai={"center"}>
              <Link to="/">Terms and Conditions</Link>
            </Container>
            <SpacerSmall />
          </>
        )}
        <Container flex={2} jc={"center"} ai={"center"}>
          <span>All Rights Reserved.</span>
          <br />
          <span>Tiny Poodles NFT © 2022.</span>
        </Container>
      </ResponsiveWrapper>
    </FooterContainer>
  );
}

export default Footer;
