import React, { useEffect, useState } from "react";
import { truncate } from "./Utilities";
import {
  claimNFTs,
  decrementMintAmount,
  getConfig,
  getData,
  incrementMintAmount,
} from "./AppActions";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { StyledButton } from "./components/styled/StyledButton.styled";
import { StyledRoundButton } from "./components/styled/StyledRoundButton.styled";
import { ResponsiveWrapper } from "./components/styled/ResponsiveWrapper.styled";
import { StyledLink } from "./components/styled/StyledLink.styled";
import { Screen } from "./components/styled/Screen.styled";
import { SpacerXSmall } from "./components/styled/SpacerXSmall.styled";
import { SpacerSmall } from "./components/styled/SpacerSmall.styled";
import { SpacerMedium } from "./components/styled/SpacerMedium.styled";
import { SpacerLarge } from "./components/styled/SpacerLarge.styled";
import { Container } from "./components/styled/Container.styled";
import { TextTitle } from "./components/styled/TextTitle.styled";
import { TextDescription } from "./components/styled/TextDescription.styled";
import { Img } from "./components/styled/Img.styled";
import { BrowserRouter as Router } from "react-router-dom";
import { Route, Routes } from "react-router";
import Header from "./components/Header";
import Footer from "./components/Footer";
import LandingPage from "./components/LandingPage";

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Click buy to mint your NFT.`);
  const [mintAmount, setMintAmount] = useState(1);
  const [connectionLoading, setConnectionLoading] = useState(false);
  const [config, setConfig] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
    USE_TERMS: false,
    SHOW_MINT: false,
  });

  // Get config on page load.
  useEffect(() => {
    getConfig(setConfig);
  }, []);

  // Get contract data whenever account changes.
  useEffect(() => {
    getData(blockchain, dispatch);
  }, [blockchain.account]);

  // =======================================================================
  // When a user chooses to connect their Metamask wallet to this dapp
  // the flow is to first update the blockchain Redux store with account
  // data and then update the data Redux store with contract data. In some
  // scenarios (eg, connecting to the contract when supply has been sold out)
  // we need a loading state that captures both of these Redux store updates
  // as a single loading process to avoid awkward re-renders. The following
  // two useEffect hooks achieve this.
  // =======================================================================
  useEffect(() => {
    if (!blockchain.loading) {
      setConnectionLoading(true);
    }
  }, [blockchain.loading]);

  useEffect(() => {
    if (!data.loading) {
      setConnectionLoading(false);
    }
  }, [data.loading]);
  // =======================================================================

  return (
    <div
      style={{
        backgroundImage: `url("/config/images/bg.png")`,
        backgroundRepeat: "repeat",
      }}
    >
      {/* Temp Link to Rarity Sniper */}
      <a href="https://raritysniper.com/nft-drops-calendar" hidden>
        Rarity Sniper Drop Calendar
      </a>
      <Router>
        <Routes>
          {/* Landing Page */}
          <Route
            path="/"
            element={
              <LandingPage
                mint={config.SHOW_MINT}
                displayCost={config.DISPLAY_COST}
                networkSymbol={config.NETWORK.SYMBOL}
              />
            }
          />
          {/* Mint Page */}
          {
            <Route
              path="/mint"
              element={
                <Screen>
                  <Header mint={config.SHOW_MINT} />
                  <Container flex={1} ai={"center"} style={{ padding: 24 }}>
                    <ResponsiveWrapper flex={1} style={{ padding: 24 }}>
                      <Container flex={1} jc={"center"} ai={"center"}>
                        <Img
                          alt={"pugs hidden"}
                          src={"/config/images/pugs_hidden.png"}
                          mnw={"300px"}
                          mnh={"300px"}
                          mw={"600px"}
                          mh={"600px"}
                          bs={"none"}
                          b={"none"}
                        />
                      </Container>
                      <SpacerLarge />
                      <Container
                        flex={2}
                        jc={"center"}
                        ai={"center"}
                        style={{
                          backgroundColor: "#fff",
                          padding: 24,
                          border: "4px solid var(--primary-text)",
                        }}
                      >
                        <TextTitle
                          style={{
                            textAlign: "center",
                            fontSize: 50,
                            color: "var(--primary-text)",
                          }}
                        >
                          {data.totalSupply} / {config.MAX_SUPPLY}
                        </TextTitle>
                        <TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--primary-text)",
                          }}
                        >
                          <StyledLink target={"_blank"} href={config.SCAN_LINK}>
                            {truncate(config.CONTRACT_ADDRESS, 15)}
                          </StyledLink>
                        </TextDescription>
                        <SpacerSmall />
                        {data.paused ? (
                          <>
                            <TextTitle
                              style={{
                                textAlign: "center",
                                color: "var(--primary-text)",
                              }}
                            >
                              Tiny Pugs NFT is currently paused.
                            </TextTitle>
                            <TextDescription
                              style={{
                                textAlign: "center",
                                color: "var(--primary-text)",
                              }}
                            >
                              Minting and transferring are disabled.
                            </TextDescription>
                          </>
                        ) : Number(data.totalSupply) >= config.MAX_SUPPLY ? (
                          <>
                            <TextTitle
                              style={{
                                textAlign: "center",
                                color: "var(--primary-text)",
                              }}
                            >
                              The sale has ended.
                            </TextTitle>
                            <TextDescription
                              style={{
                                textAlign: "center",
                                color: "var(--primary-text)",
                              }}
                            >
                              You can still find {config.NFT_NAME} on&nbsp;
                              <StyledLink
                                target={"_blank"}
                                href={config.MARKETPLACE_LINK}
                                style={{
                                  fontWeight: "bold",
                                }}
                              >
                                {config.MARKETPLACE}
                              </StyledLink>
                            </TextDescription>
                          </>
                        ) : (
                          <>
                            <TextTitle
                              style={{
                                textAlign: "center",
                                color: "var(--primary-text)",
                              }}
                            >
                              1 {config.SYMBOL} costs {config.DISPLAY_COST}{" "}
                              {config.NETWORK.SYMBOL}.
                            </TextTitle>
                            <SpacerXSmall />
                            <TextDescription
                              style={{
                                textAlign: "center",
                                color: "var(--primary-text)",
                              }}
                            >
                              Excluding gas fees.
                            </TextDescription>
                            <SpacerSmall />
                            {blockchain.account === "" ||
                            blockchain.smartContract === null ||
                            connectionLoading ? (
                              <Container ai={"center"} jc={"center"}>
                                <TextDescription
                                  style={{
                                    textAlign: "center",
                                    color: "var(--primary-text)",
                                  }}
                                >
                                  Connect to the {config.NETWORK.NAME} network
                                </TextDescription>
                                <SpacerSmall />
                                <StyledButton
                                  onClick={(e) => {
                                    e.preventDefault();
                                    dispatch(connect());
                                    getData(blockchain, dispatch);
                                  }}
                                >
                                  CONNECT
                                </StyledButton>
                                {blockchain.errorMsg !== "" ? (
                                  <>
                                    <SpacerSmall />
                                    <TextDescription
                                      style={{
                                        textAlign: "center",
                                        color: "var(--error-text)",
                                      }}
                                    >
                                      {blockchain.errorMsg}
                                    </TextDescription>
                                  </>
                                ) : null}
                              </Container>
                            ) : (
                              <>
                                <TextDescription
                                  style={{
                                    textAlign: "center",
                                    color: "var(--primary-text)",
                                  }}
                                >
                                  {feedback}
                                </TextDescription>
                                <SpacerMedium />
                                <Container
                                  ai={"center"}
                                  jc={"center"}
                                  fd={"row"}
                                >
                                  <StyledRoundButton
                                    style={{ lineHeight: 0.4 }}
                                    disabled={claimingNft ? 1 : 0}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      decrementMintAmount(
                                        mintAmount,
                                        setMintAmount
                                      );
                                    }}
                                  >
                                    -
                                  </StyledRoundButton>
                                  <SpacerMedium />
                                  <TextDescription
                                    style={{
                                      textAlign: "center",
                                      color: "var(--primary-text)",
                                    }}
                                  >
                                    {mintAmount}
                                  </TextDescription>
                                  <SpacerMedium />
                                  <StyledRoundButton
                                    disabled={claimingNft ? 1 : 0}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      incrementMintAmount(
                                        mintAmount,
                                        setMintAmount,
                                        data.maxMintPerXn
                                      );
                                    }}
                                  >
                                    +
                                  </StyledRoundButton>
                                </Container>
                                <SpacerSmall />
                                <Container
                                  ai={"center"}
                                  jc={"center"}
                                  fd={"row"}
                                >
                                  <StyledButton
                                    disabled={claimingNft ? 1 : 0}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      claimNFTs(
                                        config,
                                        blockchain,
                                        mintAmount,
                                        setFeedback,
                                        setClaimingNft,
                                        dispatch
                                      );
                                      getData(blockchain, dispatch);
                                    }}
                                  >
                                    {claimingNft ? "BUSY" : "BUY"}
                                  </StyledButton>
                                </Container>
                              </>
                            )}
                          </>
                        )}
                        <SpacerMedium />
                      </Container>
                    </ResponsiveWrapper>
                    <Container
                      jc={"center"}
                      ai={"center"}
                      style={{
                        width: "50%",
                        border: "2px dashed var(--primary-text)",
                        backgroundColor: "#fff",
                      }}
                    >
                      <TextDescription
                        style={{
                          fontSize: 14,
                          fontWeight: "bold",
                          textAlign: "center",
                          color: "var(--primary-text)",
                        }}
                      >
                        A purchase cannot be undone.
                      </TextDescription>
                    </Container>
                  </Container>
                  <Footer termsAndConditions={config.USE_TERMS} />
                </Screen>
              }
            />
          }
          {/* 404 Page */}
          <Route
            path="*"
            element={
              <Screen>
                <Header />
                <Container flex={1} ai={"center"} style={{ padding: 24 }}>
                  <SpacerSmall />
                  <ResponsiveWrapper flex={1} style={{ padding: 24 }}>
                    <Container flex={1} jc={"center"} ai={"center"}>
                      <Img
                        alt={"poods"}
                        src={"/config/images/404_collar.png"}
                        mnw={"300px"}
                        mnh={"191px"}
                        mw={"500px"}
                        mh={"319px"}
                        bs={"none"}
                        b={"none"}
                      />
                    </Container>
                    <SpacerLarge />
                    <Container
                      flex={1}
                      jc={"center"}
                      ai={"center"}
                      style={{
                        padding: 24,
                        fontWeight: "bold",
                      }}
                    >
                      <TextTitle
                        style={{
                          textAlign: "center",
                          fontSize: 85,
                          color: "-var(--primary-text)",
                        }}
                      >
                        Error 404
                      </TextTitle>
                      <TextDescription
                        style={{
                          textAlign: "center",
                          fontSize: 24,
                          color: "-var(--primary-text)",
                        }}
                      >
                        Uh oh! Looks like someone got off the leash...
                      </TextDescription>
                    </Container>
                    <SpacerLarge />
                  </ResponsiveWrapper>
                  <SpacerMedium />
                </Container>
                <Footer showTerms={config.USE_TERMS} />
              </Screen>
            }
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
