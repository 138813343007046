import React from "react";
import { SocialsContainer } from "./styled/SocialsContainer.styled";
import { SocialIcon } from "./styled/SocialIcon.styled";

function HeaderSocials() {
  return (
    <SocialsContainer>
      <a href={"https://twitter.com/TinyPoodlesNFT"} target={"_blank"}>
        <SocialIcon src={"/config/images/header_twitter_icon.png"} w={"50px"} />
      </a>
      <a href={"https://www.instagram.com/TinyPoodlesNFT/"} target={"_blank"}>
        <SocialIcon src={"/config/images/header_insta_icon.png"} w={"50px"} />
      </a>
      <a href={"https://discord.gg/poodles"} target={"_blank"}>
        <SocialIcon src={"/config/images/header_discord_icon.png"} w={"55px"} />
      </a>
    </SocialsContainer>
  );
}

export default HeaderSocials;
