// Functions specifically for use with App component.
// ==================================================

import { fetchData } from "./redux/data/dataActions";

// Subscribed to "Connect" button onClick; runs after intial connection to blockchain.
// Subscribed to "Buy" button onClick; runs after claiming NFT.
// ============================================================
// Param blockchain: object
// Param dispatch: function
export const getData = (blockchain, dispatch) => {
  if (blockchain.account !== "" && blockchain.smartContract !== null) {
    dispatch(fetchData(blockchain.account));
  }
};

// Param setConfig: function
export const getConfig = async (setConfig) => {
  const configResponse = await fetch("/config/config.json", {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
  const config = await configResponse.json();
  setConfig(config);
};

// Subscribed to "-" button onClick.
// =================================
// Param mintAmount: int
// Param setMintAmount: function
export const decrementMintAmount = (mintAmount, setMintAmount) => {
  let newMintAmount = mintAmount - 1;
  if (newMintAmount < 1) {
    newMintAmount = 1;
  }
  setMintAmount(newMintAmount);
};

// Subscribed to "+" button onClick.
// =================================
// Param mintAmount: int
// Param setMintAmount: function
export const incrementMintAmount = (
  mintAmount,
  setMintAmount,
  maxMintPerXn
) => {
  let newMintAmount = mintAmount + 1;
  if (newMintAmount > maxMintPerXn) {
    newMintAmount = maxMintPerXn;
  }
  setMintAmount(newMintAmount);
};

// Subscribed to "Buy" button onClick.
// ===================================
// Param config, blockchain: object
// Param mintAmount: int
// Param setFeedback, setClaimingNft, dispatch: function
export const claimNFTs = (
  config,
  blockchain,
  mintAmount,
  setFeedback,
  setClaimingNft,
  dispatch
) => {
  let cost = config.WEI_COST;
  let totalCostWei = String(cost * mintAmount);
  console.log("Cost: ", totalCostWei);
  setFeedback(`Minting your ${config.NFT_NAME}...`);
  setClaimingNft(true);
  blockchain.smartContract.methods
    .safeMint(mintAmount)
    .send({
      to: config.CONTRACT_ADDRESS,
      from: blockchain.account,
      value: totalCostWei,
    })
    .once("error", (err) => {
      console.log(err);
      setFeedback("Sorry, something went wrong please try again later.");
      setClaimingNft(false);
    })
    .then((receipt) => {
      console.log(receipt);
      setFeedback(
        `Wow! You've minted from the ${config.NFT_NAME} collection! Welcome to the family!`
      );
      setClaimingNft(false);
      dispatch(fetchData(blockchain.account));
    });
};
