import React, { useEffect, useState } from "react";
import { Screen } from "./styled/Screen.styled";
import Header from "./Header";
import Footer from "./Footer";
import { PoodlePart } from "./styled/PoodlePart.styled";
import { ResponsiveWrapper } from "./styled/ResponsiveWrapper.styled";
import { Container } from "./styled/Container.styled";
import { Img } from "./styled/Img.styled";
import { TextTitle } from "./styled/TextTitle.styled";
import { TextDescription } from "./styled/TextDescription.styled";
import { SpacerLarge } from "./styled/SpacerLarge.styled";
import { LoadingCurtain } from "./styled/LoadingCurtain.styled";
import { LoadingSpinner } from "./styled/LoadingSpinner.styled";

function LandingPage({ mint }) {
  const poodPartMaxIdx = 3;
  const [showcaseItemIdx, setShowcaseItemIdx] = useState(0);
  const [showcaseBodyIdx, setShowcaseBodyIdx] = useState(0);
  const [showcaseShirtIdx, setShowcaseShirtIdx] = useState(0);
  const [showcaseEyesIdx, setShowcaseEyesIdx] = useState(0);
  const [showcaseMouthsIdx, setShowcaseMouthsIdx] = useState(0);
  const [showcaseHatsIdx, setShowcaseHatsIdx] = useState(0);
  const [runShowcase, setRunShowcase] = useState(false);
  const [loadingCurtain, setLoadingCurtain] = useState(true);
  const [showLoadingSpinner, setShowLoadingSpinner] = useState(true);

  function updateShowcaseItem() {
    setShowcaseItemIdx((showcaseItemIdx) =>
      showcaseItemIdx >= poodPartMaxIdx ? 0 : showcaseItemIdx + 1
    );
  }

  function updateShowcaseBody() {
    setShowcaseBodyIdx((showcaseBodyIdx) =>
      showcaseBodyIdx >= poodPartMaxIdx ? 0 : showcaseBodyIdx + 1
    );
  }

  function updateShowcaseShirt() {
    setShowcaseShirtIdx((showcaseShirtIdx) =>
      showcaseShirtIdx >= poodPartMaxIdx ? 0 : showcaseShirtIdx + 1
    );
  }

  function updateShowcaseEyes() {
    setShowcaseEyesIdx((showcaseEyesIdx) =>
      showcaseEyesIdx >= poodPartMaxIdx ? 0 : showcaseEyesIdx + 1
    );
  }

  function updateShowcaseMouths() {
    setShowcaseMouthsIdx((showcaseMouthsIdx) =>
      showcaseMouthsIdx >= poodPartMaxIdx ? 0 : showcaseMouthsIdx + 1
    );
  }

  function updateShowcaseHats() {
    setShowcaseHatsIdx((showcaseHatsIdx) =>
      showcaseHatsIdx >= poodPartMaxIdx ? 0 : showcaseHatsIdx + 1
    );
  }

  function setAllShowcaseIntervals() {
    var intervals = [];
    intervals.push(setInterval(updateShowcaseBody, 31000));
    intervals.push(setInterval(updateShowcaseItem, 11000));
    intervals.push(setInterval(updateShowcaseShirt, 16000));
    intervals.push(setInterval(updateShowcaseEyes, 9000));
    intervals.push(setInterval(updateShowcaseMouths, 8000));
    intervals.push(setInterval(updateShowcaseHats, 13000));
    return intervals;
  }

  function liftLoadingCurtain() {
    setLoadingCurtain(false);
  }

  function hideLoadingSpinner() {
    setShowLoadingSpinner(false);
  }

  function kickOffShowcase() {
    setRunShowcase(true);
  }

  // Set intervals on component load.
  useEffect(() => {
    var intervals = setAllShowcaseIntervals();
    return () => {
      for (var i = 0; i < intervals.length; i++) {
        clearInterval(intervals[i]);
      }
    };
  }, []);

  // Set timeout on component load.
  useEffect(() => {
    var timers = [];
    timers.push(setTimeout(kickOffShowcase, 1500));
    timers.push(setTimeout(liftLoadingCurtain, 2000));
    timers.push(setTimeout(hideLoadingSpinner, 2500));
    return () => {
      for (var i = 0; i < timers.length; i++) {
        clearTimeout(timers[i]);
      }
    };
  }, []);

  return (
    <>
      <LoadingCurtain
        className={`loading-curtain-${loadingCurtain ? "idle" : "lift"}`}
      >
        <LoadingSpinner
          alt={"loading spinner"}
          src={"/config/images/header_bone.png"}
          className={`loading-spinner-${
            showLoadingSpinner ? "shake" : "hidden"
          }`}
        />
      </LoadingCurtain>
      {runShowcase && (
        <>
          <Screen>
            <Header mint={mint} />
            {/* ITEMS */}
            <PoodlePart
              className={`poodle-part-${
                showcaseItemIdx == 0
                  ? "enter"
                  : showcaseItemIdx == 1
                  ? "exit"
                  : "hidden"
              }`}
              src="config/images/showcase/items/bone.png"
            ></PoodlePart>
            <PoodlePart
              className={`poodle-part-${
                showcaseItemIdx == 1
                  ? "enter"
                  : showcaseItemIdx == 2
                  ? "exit"
                  : "hidden"
              }`}
              src="config/images/showcase/items/heart_food_bowl.png"
            ></PoodlePart>
            <PoodlePart
              className={`poodle-part-${
                showcaseItemIdx == 2
                  ? "enter"
                  : showcaseItemIdx == 3
                  ? "exit"
                  : "hidden"
              }`}
              src="config/images/showcase/items/ball.png"
            ></PoodlePart>
            <PoodlePart
              className={`poodle-part-${
                showcaseItemIdx == 3
                  ? "enter"
                  : showcaseItemIdx == 0
                  ? "exit"
                  : "hidden"
              }`}
              src="config/images/showcase/items/pood.png"
            ></PoodlePart>
            {/* BODIES */}
            <PoodlePart
              className={`poodle-part-${
                showcaseBodyIdx == 0
                  ? "enter"
                  : showcaseBodyIdx == 1
                  ? "exit"
                  : "hidden"
              }`}
              src="config/images/showcase/bodies/blue.png"
            ></PoodlePart>
            <PoodlePart
              className={`poodle-part-${
                showcaseBodyIdx == 1
                  ? "enter"
                  : showcaseBodyIdx == 2
                  ? "exit"
                  : "hidden"
              }`}
              src="config/images/showcase/bodies/golden.png"
            ></PoodlePart>
            <PoodlePart
              className={`poodle-part-${
                showcaseBodyIdx == 2
                  ? "enter"
                  : showcaseBodyIdx == 3
                  ? "exit"
                  : "hidden"
              }`}
              src="config/images/showcase/bodies/brown.png"
            ></PoodlePart>
            <PoodlePart
              className={`poodle-part-${
                showcaseBodyIdx == 3
                  ? "enter"
                  : showcaseBodyIdx == 0
                  ? "exit"
                  : "hidden"
              }`}
              src="config/images/showcase/bodies/white.png"
            ></PoodlePart>
            {/* SHIRTS */}
            <PoodlePart
              className={`poodle-part-${
                showcaseShirtIdx == 0
                  ? "enter"
                  : showcaseShirtIdx == 1
                  ? "exit"
                  : "hidden"
              }`}
              src="config/images/showcase/shirts/king_robe.png"
            ></PoodlePart>
            <PoodlePart
              className={`poodle-part-${
                showcaseShirtIdx == 1
                  ? "enter"
                  : showcaseShirtIdx == 2
                  ? "exit"
                  : "hidden"
              }`}
              src="config/images/showcase/shirts/pride_shirt.png"
            ></PoodlePart>
            <PoodlePart
              className={`poodle-part-${
                showcaseShirtIdx == 2
                  ? "enter"
                  : showcaseShirtIdx == 3
                  ? "exit"
                  : "hidden"
              }`}
              src="config/images/showcase/shirts/ref_shirt.png"
            ></PoodlePart>
            <PoodlePart
              className={`poodle-part-${
                showcaseShirtIdx == 3
                  ? "enter"
                  : showcaseShirtIdx == 0
                  ? "exit"
                  : "hidden"
              }`}
              src="config/images/showcase/shirts/tatted_chest.png"
            ></PoodlePart>
            {/* EYES */}
            <PoodlePart
              className={`poodle-part-${
                showcaseEyesIdx == 0
                  ? "enter"
                  : showcaseEyesIdx == 1
                  ? "exit"
                  : "hidden"
              }`}
              src="config/images/showcase/eyes/eyepatch.png"
            ></PoodlePart>
            <PoodlePart
              className={`poodle-part-${
                showcaseEyesIdx == 1
                  ? "enter"
                  : showcaseEyesIdx == 2
                  ? "exit"
                  : "hidden"
              }`}
              src="config/images/showcase/eyes/heart_eyes.png"
            ></PoodlePart>
            <PoodlePart
              className={`poodle-part-${
                showcaseEyesIdx == 2
                  ? "enter"
                  : showcaseEyesIdx == 3
                  ? "exit"
                  : "hidden"
              }`}
              src="config/images/showcase/eyes/money_eyes.png"
            ></PoodlePart>
            <PoodlePart
              className={`poodle-part-${
                showcaseEyesIdx == 3
                  ? "enter"
                  : showcaseEyesIdx == 0
                  ? "exit"
                  : "hidden"
              }`}
              src="config/images/showcase/eyes/warpaint_eyes.png"
            ></PoodlePart>
            {/* MOUTHS */}
            <PoodlePart
              className={`poodle-part-${
                showcaseMouthsIdx == 0
                  ? "enter"
                  : showcaseMouthsIdx == 1
                  ? "exit"
                  : "hidden"
              }`}
              src="config/images/showcase/mouths/drool.png"
            ></PoodlePart>
            <PoodlePart
              className={`poodle-part-${
                showcaseMouthsIdx == 1
                  ? "enter"
                  : showcaseMouthsIdx == 2
                  ? "exit"
                  : "hidden"
              }`}
              src="config/images/showcase/mouths/mask.png"
            ></PoodlePart>
            <PoodlePart
              className={`poodle-part-${
                showcaseMouthsIdx == 2
                  ? "enter"
                  : showcaseMouthsIdx == 3
                  ? "exit"
                  : "hidden"
              }`}
              src="config/images/showcase/mouths/retro.png"
            ></PoodlePart>
            <PoodlePart
              className={`poodle-part-${
                showcaseMouthsIdx == 3
                  ? "enter"
                  : showcaseMouthsIdx == 0
                  ? "exit"
                  : "hidden"
              }`}
              src="config/images/showcase/mouths/stache_tongue.png"
            ></PoodlePart>
            {/* HATS */}
            <PoodlePart
              className={`poodle-part-${
                showcaseHatsIdx == 0
                  ? "enter"
                  : showcaseHatsIdx == 1
                  ? "exit"
                  : "hidden"
              }`}
              src="config/images/showcase/hats/dragon_hat.png"
            ></PoodlePart>
            <PoodlePart
              className={`poodle-part-${
                showcaseHatsIdx == 1
                  ? "enter"
                  : showcaseHatsIdx == 2
                  ? "exit"
                  : "hidden"
              }`}
              src="config/images/showcase/hats/halo.png"
            ></PoodlePart>
            <PoodlePart
              className={`poodle-part-${
                showcaseHatsIdx == 2
                  ? "enter"
                  : showcaseHatsIdx == 3
                  ? "exit"
                  : "hidden"
              }`}
              src="config/images/showcase/hats/pirate_hat.png"
            ></PoodlePart>
            <PoodlePart
              className={`poodle-part-${
                showcaseHatsIdx == 3
                  ? "enter"
                  : showcaseHatsIdx == 0
                  ? "exit"
                  : "hidden"
              }`}
              src="config/images/showcase/hats/tatted_face.png"
            ></PoodlePart>
          </Screen>
          <Screen>
            <ResponsiveWrapper style={{ padding: 24 }}>
              <Container
                flex={1}
                jc={"center"}
                ai={"start"}
                style={{
                  padding: 24,
                }}
              >
                <TextTitle
                  style={{
                    textAlign: "center",
                    fontSize: 50,
                    color: "var(--primary-text)",
                  }}
                >
                  Tiny Poodles
                </TextTitle>
                <TextDescription
                  style={{
                    color: "var(--primary-text)",
                    fontWeight: "bold",
                  }}
                >
                  Tiny Poodles is a collection of 5,555 furry friends! Built
                  atop the Ethereum blockchain, each Tiny Poodle NFT is a wholly
                  unique piece of art with its own personality, crafted from
                  hand-drawn elements such as bodies, hats, eyes, and more!
                  Using our generative software we've integrated a rarity tier
                  system into this collection with a limited supply of the most
                  sought after and rare Poodles!
                  <br />
                  <br />
                  40% of the overall mint revenue will fund a community pool.
                  Every community member will be able to make their voice heard.
                  Holders of Tiny Poodles NFT will have exclusive voting rights
                  to determine what happens with our community pool. For details
                  and to get whitelisted come join our Discord community where
                  we hang out, do giveaways, and play games!
                </TextDescription>
              </Container>
              <Container flex={1} jc={"center"} ai={"center"}>
                <Img
                  alt={"tiny poodles gif"}
                  src={"/config/images/poods.gif"}
                  mnw={"300px"}
                  mnh={"300px"}
                  mw={"600px"}
                  mh={"600px"}
                />
                <SpacerLarge />
              </Container>
            </ResponsiveWrapper>
            <Footer useTerms={false} /> {/* TODO: update useTerms usage */}
          </Screen>
        </>
      )}
    </>
  );
}

export default LandingPage;
