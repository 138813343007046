import styled from "styled-components";

export const Img = styled.img`
  box-shadow: ${({ bs }) =>
    bs ? `${bs}` : "0px 5px 11px 2px rgba(0, 0, 0, 0.7)"};
  border: ${({ b }) => (b ? `${b}` : "4px solid var(--primary-text)")};
  border-radius: ${({ br }) => (br ? `${br}` : "none")};
  width: ${({ w }) => (w ? `${w}` : "30vw")};
  height: ${({ h }) => (h ? `${h}` : "30vw")};
  min-width: ${({ mnw }) => (mnw ? `${mnw}` : "none")};
  min-height: ${({ mnh }) => (mnh ? `${mnh}` : "none")};
  max-width: ${({ mw }) => (mw ? `${mw}` : "none")};
  max-height: ${({ mh }) => (mh ? `${mh}` : "none")};
`;
