import styled from "styled-components";

export const FooterContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: var(--secondary-trans);
  width: 100%;
  background-size: cover;
  background-position: center;
  padding: 12;
  webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  min-height: 75px;
  max-height: 100px;
  height: 3vh;
  border-top: 2px solid #1a1a1a;
`;
