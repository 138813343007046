import React from "react";
import { Link } from "react-router-dom";
import { Container } from "./styled/Container.styled";
import { ResponsiveWrapper } from "./styled/ResponsiveWrapper.styled";
import { SpacerMedium } from "./styled/SpacerMedium.styled";
import { VenAnchor } from "./styled/VenAnchor.styled";

function HeaderNav({ contractAddress }) {
  return (
    <Container
      flex={1}
      ai={"center"}
      style={{
        padding: 24,
        fontFamily: "poods-nav",
        fontSize: "20px",
      }}
    >
      <ResponsiveWrapper flex={1}>
        {contractAddress && (
          <>
            <Container flex={1} jc={"center"} ai={"center"}>
              <Link to="/mint" className="nav-element">
                Mint
              </Link>
            </Container>
          </>
        )}
        <Container flex={1} jc={"center"} ai={"start"}>
          <VenAnchor href={"#page-three"}>Roadmap</VenAnchor>
        </Container>
        <SpacerMedium />
        <Container flex={1} jc={"center"} ai={"start"}>
          <VenAnchor href="#page-four">Team</VenAnchor>
        </Container>
      </ResponsiveWrapper>
    </Container>
  );
}

export default HeaderNav;
