import styled from "styled-components";

export const VenAnchor = styled.a`
  min-width: 115px;
  justify-content: center;
  align-items: center;
  color: #000;
  font-weight: bold;
  text-decoration: none;
  :hover {
    text-decoration: underline;
  }
  :active {
    text-decoration: none;
  }
`;
