import styled from "styled-components";

export const HeaderTitle = styled.span`
  font-family: poods-nav;
  font-size: 24px;
  font-weight: bold;
  margin: auto;
  color: var(--primary-text);
  @media all and (max-width: 600px) {
    display: none;
  }
`;
