import React from "react";
import { HeaderLogo } from "./styled/HeaderLogo.styled";
import HeaderSocials from "./HeaderSocials";
import HeaderNav from "./HeaderNav";
import { HeaderContainer } from "./styled/HeaderContainer.styled";
import { Container } from "./styled/Container.styled";
import { Link } from "react-router-dom";
import { HeaderTitle } from "./styled/HeaderTitle.styled";
import { InnerHeaderDivLeft } from "./styled/InnerHeaderDivLeft.styled";
import { InnerHeaderDivRight } from "./styled/InnerHeaderDivRight.styled";
import { StyledLink } from "./styled/StyledLink.styled";
import { TextDescription } from "./styled/TextDescription.styled";

function Header({ mint }) {
  return (
    <>
      <HeaderContainer>
        <InnerHeaderDivLeft>
          <Link to="/" className="title-link">
            <HeaderLogo src={"../config/images/header_bone.png"} />
            <HeaderTitle>Tiny Poodles</HeaderTitle>
          </Link>
        </InnerHeaderDivLeft>

        {/*
          <Container flex={2} jc={"center"} ai={"center"}>
            <HeaderNav contractAddress={contractAddress} />
          </Container>
          */}

        <InnerHeaderDivRight>
          <HeaderSocials />
        </InnerHeaderDivRight>
      </HeaderContainer>
      {mint && (
        <Container
          jc={"center"}
          ai={"center"}
          style={{
            backgroundColor: "#e4cee4",
            padding: 6,
            border: "1px solid var(--primary-text)",
          }}
        >
          <TextDescription style={{ textAlign: "center", fontWeight: "bold" }}>
            Mint a Tiny Pug&nbsp;
            <StyledLink style={{ textDecoration: "underline" }} href={"/mint"}>
              here!
            </StyledLink>
          </TextDescription>
        </Container>
      )}
    </>
  );
}

export default Header;
