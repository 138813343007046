import styled from "styled-components";

export const SocialsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 15vw;
  min-width: 175px;
  min-height: 55px;
  max-width: 259px;
  max-height: 113px;
`;
