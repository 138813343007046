import styled from "styled-components";

export const LoadingCurtain = styled.div`
  background-color: var(--secondary);
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100vw;
  z-index: 1000000;
`;
